import { dateStringToDate } from 'helpers'
import { mapState, mapGetters, mapActions } from "vuex";
import moment from 'moment'

export default {
    data() {
        return {
            watcher: false,
            refreshAction: () => {
                this.handleRefresh()
            },
            refreshStarted: false,
        }
    },
    computed: {
        storeWaypointID() {
            return this.store.storeWaypointID
        },
        refreshActive() {
            if (this.refreshStarted) return true
            return this.reloadInformation[this.storeWaypointID] ? true : false
        },
        reloadProgress() {
            if (!this.refreshActive) return false


            return reloadInformation.reloadItemsLoaded / reloadInformation.reloadItemsToLoad
        },
        lastRefreshed() {
            return moment.utc(this.store.storePOSRefreshDateTimeUTC).format('M/D/YY')
        },
        syncStatusString() {
            if (!this.refreshActive) return `Last Refreshed on ${this.lastRefreshed}`
            let reloadInformation = this.reloadInformation[this.storeWaypointID]

            return `Reload in progress. ${reloadInformation.numberOfRecordsLoaded} items have been loaded.`
        },
        ...mapGetters("POSReloadStore", ["reloadInformation"]),
        ...mapGetters(['pos', 'store']),
    },
    methods: {
        handleRefresh() {
            if (this.pos == 'grab') return
            if (!this.refreshActive) {
                this.refreshStarted = true
                this.toast('Checking menu refresh progress.')

                this.reloadPos(this.storeWaypointID)
                    .then(() => {
                    })
            }
        },
        initProgressWatcher() {
            this.checkProgress();
            this.watcher = setInterval(() => {
                if (!this.refreshActive) return
                this.checkProgress()
            }, 15000)
        },
        checkProgress() {
            this.toast('Checking menu refresh progress')

            this.checkReloadProgress(this.storeWaypointID)
                .then(() => {
                    this.checkReloadFinished()
                })
        },
        checkReloadFinished() {
            let reloadInformation = this.reloadInformation[this.storeWaypointID]
            if (!reloadInformation) return
            this.refreshStarted = false
            if (reloadInformation.requestComplete) {
                clearInterval(this.watcher)

                this.toast('Refreshed store!', 'success')
                this.getActiveStore(this.storeWaypointID)
                this.completePOSReload(this.storeWaypointID)
            }
        },
        ...mapActions("POSReloadStore", ["reloadPos", "completePOSReload", "checkReloadProgress"]),
        ...mapActions(['getActiveStore']),
    },
    mounted() {

    }
}
