var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-simple-table", {
    staticClass: "integrity-report",
    attrs: { dense: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-left" }, [
                  _vm._v(" Inventory Item ID "),
                ]),
                _c("th", { staticClass: "text-left" }, [_vm._v(" Item name ")]),
                _c("th", { staticClass: "text-left" }, [_vm._v(" Errors ")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.items, function (item) {
                return _c("tr", { key: item.inventoryItemID }, [
                  _c(
                    "td",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "store-product-edit",
                              params: { product: item.inventoryItemID },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.inventoryItemID) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "store-product-edit",
                              params: { product: item.inventoryItemID },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.inventoryItemName) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text-left" }, [
                    _c(
                      "ul",
                      { staticClass: "pl-0" },
                      _vm._l(item.errors, function (e) {
                        return _c("li", [_vm._v(_vm._s(e))])
                      }),
                      0
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }