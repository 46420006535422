var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "ml-5 black--text text-subtitle-2",
      attrs: { "no-gutters": "" },
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { staticClass: "align-items-center" },
            [
              _c("v-col", { attrs: { cols: "1" } }, [_vm._v(" Date: ")]),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "datemenu",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px",
                      },
                      on: { input: _vm.onDateToggled },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "#93BD20",
                                        label: _vm.datePlaceholder,
                                        "prepend-icon": "mdi-calendar-blank",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.dateDisplay,
                                        callback: function ($$v) {
                                          _vm.dateDisplay = $$v
                                        },
                                        expression: "dateDisplay",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.dateMenu,
                        callback: function ($$v) {
                          _vm.dateMenu = $$v
                        },
                        expression: "dateMenu",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          color: "#93BD20",
                          min: _vm.minDate,
                          max: _vm.maxDate,
                        },
                        on: {
                          input: function ($event) {
                            _vm.dateMenu = false
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "align-items-center", attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "1" } }, [_vm._v(" Time: ")]),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "timemenu",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px",
                      },
                      on: { input: _vm.onTimeToggled },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "#93BD20",
                                        label: _vm.timePlaceholder,
                                        "prepend-icon":
                                          "mdi-clock-time-four-outline",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.timeDisplay,
                                        callback: function ($$v) {
                                          _vm.timeDisplay = $$v
                                        },
                                        expression: "timeDisplay",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.timeMenu,
                        callback: function ($$v) {
                          _vm.timeMenu = $$v
                        },
                        expression: "timeMenu",
                      },
                    },
                    [
                      _vm.timeMenu
                        ? _c("v-time-picker", {
                            attrs: {
                              color: "#93BD20",
                              "ampm-in-title": "",
                              "full-width": "",
                            },
                            model: {
                              value: _vm.time,
                              callback: function ($$v) {
                                _vm.time = $$v
                              },
                              expression: "time",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "align-items-center", attrs: { "no-gutters": "" } },
            [
              _vm._v(" Check menu integrity "),
              _c(
                "v-col",
                { staticClass: "pb-2 ml-2 mr-2", attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      label: "Hours",
                      items: _vm.menuIntegrityCheckItems,
                      "persistent-hint": "",
                      "return-object": "",
                      "single-line": "",
                    },
                    on: { input: _vm.onMenuIntegrityDelayChange },
                    model: {
                      value: _vm.menuIntegrityCheckDelay,
                      callback: function ($$v) {
                        _vm.menuIntegrityCheckDelay = $$v
                      },
                      expression: "menuIntegrityCheckDelay",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" before sync. "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }