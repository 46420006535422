<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="inProgress">
        Manage Menu Sync
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-card-title class="text-h6 font-weight-medium">
        Manage Menu Sync
      </v-card-title>
      <v-divider class="ma-0" />
      <v-progress-linear v-if="loading" indeterminate color="#93BD20" />
      <v-card-text>
        <v-row no-gutters class="mt-1 align-items-center">
          <!-- <v-col
            md="2"
            class="text-md-body-2 font-weight-bold black--text"
            align-self="center"
          >
            Sync Time <br />(local to store)
          </v-col> -->
          <v-col md="10">
            <v-radio-group
              v-model="syncHow"
              dense
              hide-details
              class="select-radios"
            >
              <v-radio label="Sync Now" value="now"></v-radio>
              <v-radio
                label="Sync at future date and time"
                value="future"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider />
        <FutureSyncStatus
          class="mt-1"
          v-if="showScheduledStatus"
          :manage="true"
          @delete="onDelete"
        />
        <FutureSyncPicker
          class="mt-1"
          v-if="showSchedulePicker"
          @input="onScheduleChange"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey" class="ma-2 white--text" @click="onCancel">
          Cancel
          <v-icon right dark> mdi-close</v-icon>
        </v-btn>
        <v-btn
          :disabled="!valid"
          color="#93BD20"
          class="ma-2 white--text"
          @click="onDone"
        >
          DONE
          <v-icon right dark> mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SyncStatus } from "helpers/future_sync";

import FutureSyncPicker from "./future_sync_picker";
import FutureSyncStatus from "./future_sync_scheduled_status";

export default {
    name: "FutureSyncDialog",
    components: { FutureSyncPicker, FutureSyncStatus },
    data() {
        return {
            dialog: false,
            syncHow: undefined,
            syncScheduleInfo: {},
            loading: false,
        };
    },
    computed: {
        showSchedulePicker() {
            return (
                this.syncHow == "future" &&
        (this.syncStatus.status == SyncStatus.Unscheduled ||
          this.syncStatus.status == SyncStatus.Completed)
            );
        },
        showScheduledStatus() {
            return (
                this.syncHow == "future" &&
        this.syncStatus.status != SyncStatus.Unscheduled && this.syncStatus.status != SyncStatus.Completed
            );
        },
        valid() {
            if (this.syncHow == undefined) return false;
            if (this.syncHow == "now") return true;
            if (this.showSchedulePicker) {
                return (
                    this.syncScheduleInfo.date != undefined &&
          this.syncScheduleInfo.time != undefined &&
          this.syncScheduleInfo.delay != undefined
                );
            }
            return false;
        },
        inProgress() {
            return this.syncStatus.status == SyncStatus.InProgress;
        },
        ...mapGetters("FutureSyncStore", ["syncStatus"]),
    },
    methods: {
        onCancel() {
            this.dialog = false;
        },
        onDone() {
            if (this.syncHow == "now") {
                this.loading = true;
                this.syncNow(this.$route.params.store)
                    .then(() => {
                        this.toast("Menu Sync Scheduled", "success");
                    })
                    .catch(() => {
                        this.toast(
                            "Failed to schedule sync. Please try again later",
                            "error"
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                        this.dialog = false;
                    });
                return;
            }

            if (this.showSchedulePicker) {
                var payload = {
                    ...this.syncScheduleInfo,
                    storeWaypointID: this.$route.params.store,
                };
                this.loading = true;
                this.scheduleSync(payload)
                    .then(() => {
                        this.toast("Menu Sync Scheduled", "success");
                    })
                    .catch(() => {
                        this.toast(
                            "Failed to schedule sync. Please try again later",
                            "error"
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                        this.dialog = false;
                    });
            }
        },
        onScheduleChange(val) {
            this.syncScheduleInfo = val;
        },
        onDelete() {
            this.loading = true;
            this.deleteSync(this.$route.params.store)
                .then(() => {
                    this.toast("Sync Cancelled", "success");
                })
                .catch(() => {
                    this.toast("Failed to cancel sync. Please try again later", "error");
                })
                .finally(() => {
                    this.loading = false;
                    this.dialog = false;
                });
        },
        ...mapActions("FutureSyncStore", ["scheduleSync", "deleteSync", "syncNow"]),
    },
};
</script>

<style>
.select-radios label {
  font-weight: normal;
}
</style>