<template>
    <div v-if="Object.keys(store).length">
        <store-settings-header :titleBarActions="titleBarActions" />
        <v-container fluid class="pa-0">
            <v-row no-gutters class="mb-1 button-row" v-if="showMenuCopy">
                <v-col cols="12">
                    <v-card rounded="0" elevation="0">
                        <v-card-title> Menu Sync </v-card-title>
                        <v-progress-linear
                            v-if="syncLoading"
                            indeterminate
                            color="#93BD20"
                        />

                        <v-card-text>
                            <FutureSyncScheduledStatus
                                class="mb-2"
                                @delete="onDeleteSync"
                            />
                            <FutureSyncDialog />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row
                v-if="showSyncButton"
                no-gutters
                class="button-row pl-4 pt-2 pb-2 mb-2"
            >
                <v-col lg="3">
                    <v-btn
                        color="#93BD20"
                        class="white--text"
                        @click="handleRefresh"
                        :disabled="refreshActive"
                    >
                        SYNC POS MENU ITEMS
                        <v-icon right dark>sync_alt</v-icon>
                    </v-btn>
                </v-col>
                <!-- <v-col class="align-center" lg="4">{{syncStatusString}}</v-col> -->
            </v-row>


            <v-row no-gutters class="button-row pl-4 pt-2 pb-2 mb-2">
                <v-col lg="3">
                    <v-btn
                        color="#93BD20"
                        class="white--text"
                        @click="runMenuInjection"
                    >
                        RUN MENU INJECTION TEST
                        <v-icon right dark>vaccines</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <!-- <v-row
        v-if="showMenuCopy"
        no-gutters
        class="button-row pl-4 pt-2 pb-2 mb-2"
      >
        <v-col lg="3">
          <v-btn color="#93BD20" class="white--text" @click="copyMenuToProd">
            COPY MENU TO PRODUCTION
            <v-icon right dark>file_copy</v-icon>
          </v-btn>
        </v-col>
      </v-row> -->
            <v-row
                v-if="showPartnerCopy"
                no-gutters
                class="button-row pl-4 pt-2 pb-2 mb-2"
            >
                <v-col lg="3">
                    <v-btn
                        color="#93BD20"
                        class="white--text"
                        @click="showSyncModal"
                    >
                        REQUEST MENU COPY
                        <v-icon right dark>file_copy</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row no-gutters class="button-row pl-4 pt-2 pb-2 mb-2">
                <v-col lg="3">
                    <v-btn
                        color="#93BD20"
                        class="white--text"
                        @click="checkIntegrity"
                    >
                        CHECK MENU INTEGRITY
                        <v-icon right dark>receipt_long</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="align-center" lg="4">
                    {{ menuIntegrityStatusString }}
                </v-col>
            </v-row>
            <v-row
                v-if="showIntegrityReport"
                no-gutters
                class="pl-4 pt-2 pb-2 mb-2"
            >
                <v-col>
                    <h2>Menu integrity report</h2>
                    <menu-integrity-report
                        class="pt-3"
                        :items="integrityReportItems"
                    />
                </v-col>
            </v-row>
        </v-container>

        <EditingModal size="sm" v-if="syncModal">
            <AlertDialog
                title="Menu Sync"
                :onExit="() => (this.syncModal = false)"
                :actions="modalActions"
            >
                <div>
                    Please select continue to submit your request to sync to
                    production, in addition, if there are any special notes
                    please enter below. Upon successful menu and pricing
                    validation we will sync to production after store has
                    closed, if there are any errors we will let you know. For
                    additional help reach out to support@getgrab.com .
                </div>
                <textarea v-model="modalMessage" class="modalMessage" />
                <div>Thank you!</div>
            </AlertDialog>
        </EditingModal>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import storeSettingsHeader from "components/store_settings_header.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import POSRefreshController from "mixins/pos_refresh_controller.js";
import MenuIntegrityReport from "components/menu_integrity_report";
import { OperationStatus } from "helpers/common";
import moment from "moment";
import FutureSyncDialog from "components/storesettings/future_sync_dialog";
import FutureSyncScheduledStatus from "components/storesettings/future_sync_scheduled_status";

export default {
    name: "StoreSettingsMenuFunctions",
    components: {
        storeSettingsHeader,
        EditingModal,
        AlertDialog,
        MenuIntegrityReport,
        FutureSyncDialog,
        FutureSyncScheduledStatus,
    },
    mixins: [POSRefreshController],
    data() {
        return {
            titleBarActions: [],
            modalActions: [
                {
                    type: "green",
                    display: "Continue",
                    noIcon: true,
                    run: this.sendSyncEmail,
                },
            ],
            modalMessage: "",
            syncModal: false,
            syncLoading: false,
        };
    },
    computed: {
        showSyncButton() {
            return this.pos != "grab";
        },

        showMenuCopy() {
            return true;
            return this.superuser;
        },
        showPartnerCopy() {
            return !this.showMenuCopy;
        },
        menuIntegrity() {
            return this.getMenuIntegrity(this.store.storeWaypointID);
        },
        menuIntegrityStatusString() {
            if (
                this.menuIntegrity &&
                this.menuIntegrity.status === OperationStatus.Succeeded
            ) {
                return `Last integrity check ${
                    this.menuIntegrity.data.success
                        ? "succeeded"
                        : "finished with errors"
                } at ${moment(this.menuIntegrity.lastUpdatedOn).format(
                    "YYYY-MM-DD HH:mm:ss"
                )}`;
            } else {
                return null;
            }
        },
        showIntegrityReport() {
            return (
                this.menuIntegrity &&
                this.menuIntegrity.status === OperationStatus.Succeeded &&
                this.menuIntegrity.data.success === false
            );
        },
        integrityReportItems() {
            return (this.menuIntegrity && this.menuIntegrity.data.errors) || [];
        },
        OperationStatus: () => OperationStatus,
        ...mapGetters([
            "store",
            "pos",
            "user",
            "superuser",
            "getMenuIntegrity",
        ]),
    },
    methods: {
        showSyncModal() {
            this.syncModal = true;
        },
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = `${this.store.storeName} - Settings`;
            }
        },
        sendSyncEmail() {
            this.syncModal = false;
            this.sendMenuSyncAlert(this.modalMessage);
        },
        checkIntegrity() {
            this.toast("Starting integrity check...");
            this.checkMenuIntegrity(this.store.storeWaypointID)
                .then((data) => {
                    if (data.success) {
                        this.toast("Menu is good.", "success");

                        return;
                    }

                    this.toast(
                        "Menu is broken, see details below and check your email for exceptions.",
                        "error"
                    );
                })
                .catch((e) => {
                    console.debug(e);
                    this.toast("Could not check integrity.", "error");
                });
        },
        copyMenuToProd() {
            this.toast("Starting copy...");

            this.syncStore(this.store.storeWaypointID)
                .then(() => {
                    this.toast(
                        "Success! The menu will finish copying in approx. 5 min.",
                        "success"
                    );
                })
                .catch(() => {
                    this.toast("Could not copy menu.", "error");
                });
        },
        onDeleteSync() {
            this.syncLoading = true;
            this.deleteSync(this.$route.params.store)
                .then(() => {
                    this.toast("Sync Cancelled", "success");
                })
                .catch(() => {
                    this.toast(
                        "Failed to cancel sync. Please try again later",
                        "error"
                    );
                })
                .finally(() => {
                    this.syncLoading = false;
                });
        },
        runMenuInjection() {
            this.runMenuInjectionTest(this.$route.params.store).then((res) => {
                console.log(res);
                if (res.success) this.toast("Success", "success");
                else this.toast("Error testing menu injection", "error");
            });
        },
        ...mapActions([
            "sendMenuSyncAlert",
            "syncStore",
            "checkMenuIntegrity",
            "runMenuInjectionTest",
        ]),
        ...mapActions("FutureSyncStore", ["getMenuSyncStatus", "deleteSync"]),
    },
    watch: {
        store() {
            this.resolvePageTitle();
        },
    },
    mounted() {
        this.initProgressWatcher();
        this.getMenuSyncStatus(this.$route.params.store);
    },
    created() {
        this.resolvePageTitle();
    },
};
</script>

<style lang="scss" scoped>
.modalMessage {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    height: 25vh;
    border: 1px solid #efefef;
}

.button-row {
    border-bottom: 1px solid #c9c9c9;
}
</style>


