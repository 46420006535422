var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "50%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", disabled: _vm.inProgress } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Manage Menu Sync ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { elevation: "0" } },
        [
          _c("v-card-title", { staticClass: "text-h6 font-weight-medium" }, [
            _vm._v(" Manage Menu Sync "),
          ]),
          _c("v-divider", { staticClass: "ma-0" }),
          _vm.loading
            ? _c("v-progress-linear", {
                attrs: { indeterminate: "", color: "#93BD20" },
              })
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-1 align-items-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "10" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "select-radios",
                          attrs: { dense: "", "hide-details": "" },
                          model: {
                            value: _vm.syncHow,
                            callback: function ($$v) {
                              _vm.syncHow = $$v
                            },
                            expression: "syncHow",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "Sync Now", value: "now" },
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: "Sync at future date and time",
                              value: "future",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _vm.showScheduledStatus
                ? _c("FutureSyncStatus", {
                    staticClass: "mt-1",
                    attrs: { manage: true },
                    on: { delete: _vm.onDelete },
                  })
                : _vm._e(),
              _vm.showSchedulePicker
                ? _c("FutureSyncPicker", {
                    staticClass: "mt-1",
                    on: { input: _vm.onScheduleChange },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 white--text",
                  attrs: { color: "blue-grey" },
                  on: { click: _vm.onCancel },
                },
                [
                  _vm._v(" Cancel "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(" mdi-close"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 white--text",
                  attrs: { disabled: !_vm.valid, color: "#93BD20" },
                  on: { click: _vm.onDone },
                },
                [
                  _vm._v(" DONE "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(" mdi-check"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }