<template>
  <v-row no-gutters class="align-items-center">
    <v-col cols="12">
      <v-row v-if="scheduled" no-gutters class="ml-2 align-items-center">
        <v-col
          md="2"
          class="text-md-body-2 font-weight-bold black--text"
          align-self="center"
        >
          Scheduled Sync <br />(local to store)
        </v-col>
        <v-col cols="10">
          <v-row no-gutters class="align-items-center">
            <span v-if="failed" class="red--text"> {{ scheduleDisplay }} </span>
            <span v-else> {{ scheduleDisplay }} </span>
            <v-btn
              v-if="!failed && !inProgress && !completed"
              icon
              color="red"
              class="ml-1"
              @click="deleteConfirm = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn v-else-if="!inProgress && !completed" text color="#93BD20" @click="onScheduleNewSync">
              SCHEDULE NEW SYNC
            </v-btn>
          </v-row>
          <v-row no-gutters v-if="inProgress">
            <v-col cols="6">
              <v-progress-linear indeterminate color="#93BD20" />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="deleteConfirm" class="align-items-center">
            Are you sure?
            <v-btn plain color="red" @click="deleteSync">
              YES, delete sync
            </v-btn>
            <v-btn plain color="black" @click="deleteConfirm = false">
              NO
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="showIntegrityReport" class="mb-2">
        <v-col cols="12">
          <v-row no-gutters class="warning-banner pa-2 mt-2 mb-2 text-body-2">
            The automatic menu integrity check failed for the following items.
            Please resolve.
          </v-row>
          <MenuIntegrityReport
            class="ml-2"
            :items="syncStatus.menuIntegrity.errors"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import MenuIntegrityReport from "components/menu_integrity_report";
import { SyncStatus } from "../../helpers/future_sync";

export default {
    name: "FutureSyncScheduledStatus",
    data() {
        return {
            deleteConfirm: false,
        };
    },
    components: { MenuIntegrityReport },
    props: {
        manage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        scheduled() {
            return this.syncStatus.status != SyncStatus.Unscheduled;
        },
        scheduleDisplay() {
            if (this.completed) return `Completed on ${this.lastUpdatedDisplay}`
            if (this.inProgress) return `Menu Sync in Progress...`;
            if (this.failed)
                return `Failed on ${this.lastUpdatedDisplay}`;
            return `Scheduled for ${this.dateDisplay} at ${this.timeDisplay}`;
        },
        timeDisplay() {
            if (this.syncStatus.time == undefined) return undefined;
            return moment(this.syncStatus.time, "HH-mm").format("hh:mm A");
        },
        dateDisplay() {
            if (this.syncStatus.date == undefined) return undefined;
            return moment(this.syncStatus.date, "YYYY-MM-DD").format(
                "dddd, MMMM Do YYYY"
            );
        },
        lastUpdatedDisplay() {
            return moment.unix(this.syncStatus.lastUpdatedTimestamp).local().format("dddd, MMMM Do YYYY, h:mma")
        },
        showIntegrityReport() {
            return (
                this.syncStatus.status == SyncStatus.ScheduledWithWarning ||
        this.syncStatus.status == SyncStatus.Failed
            );
        },
        failed() {
            return this.syncStatus.status == SyncStatus.Failed;
        },
        inProgress() {
            return this.syncStatus.status == SyncStatus.InProgress;
        },
        completed() {
            return this.syncStatus.status == SyncStatus.Completed
        },
        ...mapGetters("FutureSyncStore", ["syncStatus"]),
    },
    methods: {
        deleteSync() {
            this.deleteConfirm = false;
            this.$emit("delete");
        },
        onSyncNow() {
            this.deleteSync();
        },
        onScheduleNewSync() {
            this.deleteSync();
        },
    },
};
</script>

<style scoped>
.warning-banner {
  background-color: #ffcdd2;
  color: #f44336;
  border-radius: 5px;
  border-color: #f44336;
  border-width: 1px;
  border-style: solid;
}
</style>