<template>
    <v-simple-table dense class="integrity-report">
        <template v-slot:default>
            <thead>
            <tr>
                <th class="text-left">
                    Inventory Item ID
                </th>
                <th class="text-left">
                    Item name
                </th>
                <th class="text-left">
                    Errors
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in items"
                :key="item.inventoryItemID"
            >
                <td class="text-left">
                    <router-link
                        :to="{name: 'store-product-edit', params: {product: item.inventoryItemID}}"
                    >
                        {{ item.inventoryItemID }}
                    </router-link>
                </td>
                <td class="text-left">
                    <router-link
                        :to="{name: 'store-product-edit', params: {product: item.inventoryItemID}}"
                    >
                        {{ item.inventoryItemName }}
                    </router-link>
                </td>
                <td class="text-left">
                    <ul class="pl-0">
                        <li v-for="e in item.errors">{{e}}</li>
                    </ul>
                </td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    name: "menu_integrity_report",
    props: {
        items: {
            type: Array,
            default: [],
        }
    }
}
</script>

<style lang="scss" scoped>
    .integrity-report {
        td,th {
            vertical-align: middle;
        }
    }
</style>
