<template>
  <v-row no-gutters class="ml-5 black--text text-subtitle-2">
    <v-col cols="12">
      <v-row class="align-items-center">
        <v-col cols="1"> Date: </v-col>
        <v-col cols="4">
          <v-menu
            ref="datemenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            @input="onDateToggled"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                color="#93BD20"
                v-model="dateDisplay"
                :label="datePlaceholder"
                prepend-icon="mdi-calendar-blank"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="#93BD20"
              v-model="date"
              :min="minDate"
              :max="maxDate"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters class="align-items-center">
        <v-col cols="1"> Time: </v-col>
        <v-col cols="4">
          <v-menu
            ref="timemenu"
            v-model="timeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            @input="onTimeToggled"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                color="#93BD20"
                v-model="timeDisplay"
                :label="timePlaceholder"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              color="#93BD20"
              v-if="timeMenu"
              ampm-in-title
              v-model="time"
              full-width
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters class="align-items-center">
        Check menu integrity
        <v-col cols="2" class="pb-2 ml-2 mr-2">
          <v-select
            dense
            hide-details
            label="Hours"
            v-model="menuIntegrityCheckDelay"
            :items="menuIntegrityCheckItems"
            persistent-hint
            return-object
            single-line
            @input="onMenuIntegrityDelayChange"
          />
        </v-col>
        before sync.
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
    name: "FutureSyncPicker",
    data() {
        return {
            dateMenu: false,
            timeMenu: false,
            date: undefined,
            time: undefined,
            menuIntegrityCheckDelay: 6,
        };
    },
    computed: {
        timeDisplay() {
            if (this.time == undefined) return undefined;
            return moment(this.time, "HH-mm").format("hh:mm A");
        },
        dateDisplay() {
            if (this.date == undefined) return undefined;
            return moment(this.date, "YYYY-MM-DD").format("dddd, MMMM Do YYYY");
        },
        timePlaceholder() {
            if (this.time === undefined || this.timeMenu) return "Time";
            return "";
        },
        datePlaceholder() {
            if (this.date === undefined || this.dateMenu) return "Date";
            return "";
        },
        minDate() {
            return moment().format("YYYY-MM-DD");
        },
        maxDate() {
            return moment().add(1, "M").format("YYYY-MM-DD");
        },
        menuIntegrityCheckItems() {
            return _.range(1, 10).map((el) => {
                return {
                    text: `${el} ${el == 1 ? "hour" : "hours"}`,
                    value: el,
                };
            });
        },
    },
    methods: {
        onTimeToggled(shown) {
            this.emitInput()
        },
        onDateToggled(shown) {
            this.emitInput()
        },
        onMenuIntegrityDelayChange(val) {
            this.emitInput();
        },
        emitInput() {
            this.$emit("input", {
                time: this.time,
                date: this.date,
                delay: this.menuIntegrityCheckDelay,
            });
        },
    },
};
</script>

<style>
</style>