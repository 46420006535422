var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "align-items-center", attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.scheduled
            ? _c(
                "v-row",
                {
                  staticClass: "ml-2 align-items-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-md-body-2 font-weight-bold black--text",
                      attrs: { md: "2", "align-self": "center" },
                    },
                    [
                      _vm._v(" Scheduled Sync "),
                      _c("br"),
                      _vm._v("(local to store) "),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "align-items-center",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _vm.failed
                            ? _c("span", { staticClass: "red--text" }, [
                                _vm._v(" " + _vm._s(_vm.scheduleDisplay) + " "),
                              ])
                            : _c("span", [
                                _vm._v(" " + _vm._s(_vm.scheduleDisplay) + " "),
                              ]),
                          !_vm.failed && !_vm.inProgress && !_vm.completed
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-1",
                                  attrs: { icon: "", color: "red" },
                                  on: {
                                    click: function ($event) {
                                      _vm.deleteConfirm = true
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-delete")])],
                                1
                              )
                            : !_vm.inProgress && !_vm.completed
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "#93BD20" },
                                  on: { click: _vm.onScheduleNewSync },
                                },
                                [_vm._v(" SCHEDULE NEW SYNC ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.inProgress
                        ? _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "#93BD20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.deleteConfirm
                        ? _c(
                            "v-row",
                            {
                              staticClass: "align-items-center",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _vm._v(" Are you sure? "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { plain: "", color: "red" },
                                  on: { click: _vm.deleteSync },
                                },
                                [_vm._v(" YES, delete sync ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { plain: "", color: "black" },
                                  on: {
                                    click: function ($event) {
                                      _vm.deleteConfirm = false
                                    },
                                  },
                                },
                                [_vm._v(" NO ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showIntegrityReport
            ? _c(
                "v-row",
                { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "warning-banner pa-2 mt-2 mb-2 text-body-2",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _vm._v(
                            " The automatic menu integrity check failed for the following items. Please resolve. "
                          ),
                        ]
                      ),
                      _c("MenuIntegrityReport", {
                        staticClass: "ml-2",
                        attrs: { items: _vm.syncStatus.menuIntegrity.errors },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }