var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Object.keys(_vm.store).length
    ? _c(
        "div",
        [
          _c("store-settings-header", {
            attrs: { titleBarActions: _vm.titleBarActions },
          }),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _vm.showMenuCopy
                ? _c(
                    "v-row",
                    {
                      staticClass: "mb-1 button-row",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { rounded: "0", elevation: "0" } },
                            [
                              _c("v-card-title", [_vm._v(" Menu Sync ")]),
                              _vm.syncLoading
                                ? _c("v-progress-linear", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "#93BD20",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "v-card-text",
                                [
                                  _c("FutureSyncScheduledStatus", {
                                    staticClass: "mb-2",
                                    on: { delete: _vm.onDeleteSync },
                                  }),
                                  _c("FutureSyncDialog"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSyncButton
                ? _c(
                    "v-row",
                    {
                      staticClass: "button-row pl-4 pt-2 pb-2 mb-2",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: "#93BD20",
                                disabled: _vm.refreshActive,
                              },
                              on: { click: _vm.handleRefresh },
                            },
                            [
                              _vm._v(" SYNC POS MENU ITEMS "),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v("sync_alt"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                {
                  staticClass: "button-row pl-4 pt-2 pb-2 mb-2",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { lg: "3" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "#93BD20" },
                          on: { click: _vm.runMenuInjection },
                        },
                        [
                          _vm._v(" RUN MENU INJECTION TEST "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("vaccines"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showPartnerCopy
                ? _c(
                    "v-row",
                    {
                      staticClass: "button-row pl-4 pt-2 pb-2 mb-2",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: { color: "#93BD20" },
                              on: { click: _vm.showSyncModal },
                            },
                            [
                              _vm._v(" REQUEST MENU COPY "),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v("file_copy"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                {
                  staticClass: "button-row pl-4 pt-2 pb-2 mb-2",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { lg: "3" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "#93BD20" },
                          on: { click: _vm.checkIntegrity },
                        },
                        [
                          _vm._v(" CHECK MENU INTEGRITY "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("receipt_long"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "align-center", attrs: { lg: "4" } },
                    [_vm._v(" " + _vm._s(_vm.menuIntegrityStatusString) + " ")]
                  ),
                ],
                1
              ),
              _vm.showIntegrityReport
                ? _c(
                    "v-row",
                    {
                      staticClass: "pl-4 pt-2 pb-2 mb-2",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("h2", [_vm._v("Menu integrity report")]),
                          _c("menu-integrity-report", {
                            staticClass: "pt-3",
                            attrs: { items: _vm.integrityReportItems },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.syncModal
            ? _c(
                "EditingModal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "AlertDialog",
                    {
                      attrs: {
                        title: "Menu Sync",
                        onExit: () => (this.syncModal = false),
                        actions: _vm.modalActions,
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Please select continue to submit your request to sync to production, in addition, if there are any special notes please enter below. Upon successful menu and pricing validation we will sync to production after store has closed, if there are any errors we will let you know. For additional help reach out to support@getgrab.com . "
                        ),
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.modalMessage,
                            expression: "modalMessage",
                          },
                        ],
                        staticClass: "modalMessage",
                        domProps: { value: _vm.modalMessage },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.modalMessage = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [_vm._v("Thank you!")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }